import {
  FulfillmentPage,
  GeolocationMapPage,
  WarehouseStockPage,
  PipelineStockPage,
  CallOffPage,
  DeliveryTimePage,
  MidTermCapacitiesPage,
  LongTermCapacitiesPage,
  ServiceLevelPage,
  NoCreditCarsLoan,
  StockLocationPage,
  DataQualityPage,
  CampaMRTStatePage
} from './pageComponents';
import { ReactComponent as TruckIcon } from 'assets/icons/truckIcon.svg';
import { ReactComponent as MarketIcon } from 'assets/icons/marketIcon.svg';

export enum OutboundPaths {
  transitsToPast = '/outbound/transit/fulfillment',
  geolocation = '/outbound/transit/geolocation',
  geolocationMap = '/outbound/transit/map',
  warehouseStock = '/outbound/stock/warehouse',
  pipelineStock = '/outbound/stock/pipeline',
  transits = '/outbound/callOff/tracking',
  deliveryTime = '/outbound/delivery',
  landing = '/outbound/landing',
  midTermCapacities = '/outbound/mid-term-capacities',
  longTermCapacities = '/outbound/long-term-capacities',
  serviceLevel = '/outbound/service-level',
  noCreditCarsLoan = '/outbound/cars-with-no-credit-loan',
  stockLocation = '/outbound/analytics/stock-location',
  dataQuality = '/outbound/analytics/data-quality',
  campaMRTState = '/outbound/analytics/campa-mrt-state'
}

export const OUTBOUND_ROUTES = [
  {
    name: 'outbound',
    icon: TruckIcon,
    subItems: [
      {
        name: 'analytics',
        subItems: [
          {
            name: 'stockLocation',
            path: OutboundPaths.stockLocation,
            element: StockLocationPage
          },
          {
            name: 'dataQuality',
            path: OutboundPaths.dataQuality,
            element: DataQualityPage
          },
          {
            name: 'campaMRTState',
            path: OutboundPaths.campaMRTState,
            element: CampaMRTStatePage
          }
        ]
      },
      {
        name: 'geolocationMap',
        path: OutboundPaths.geolocationMap,
        element: GeolocationMapPage
      },
      {
        name: 'capacities',
        subItems: [
          {
            name: 'midTermCapacities',
            path: OutboundPaths.midTermCapacities,
            element: MidTermCapacitiesPage
          },
          {
            name: 'longTermCapacities',
            path: OutboundPaths.longTermCapacities,
            element: LongTermCapacitiesPage
          }
        ]
      },
      {
        name: 'serviceLevel',
        path: OutboundPaths.serviceLevel,
        element: ServiceLevelPage
      },
      {
        name: 'transitsToPast',
        path: OutboundPaths.transitsToPast,
        element: FulfillmentPage
      },
      {
        name: 'noCreditCarsLoan',
        path: OutboundPaths.noCreditCarsLoan,
        element: NoCreditCarsLoan
      }
    ]
  },
  {
    name: 'sales',
    icon: MarketIcon,
    subItems: [
      {
        name: 'warehouseStock',
        path: OutboundPaths.warehouseStock,
        element: WarehouseStockPage
      },
      {
        name: 'pipelineStock',
        path: OutboundPaths.pipelineStock,
        element: PipelineStockPage
      },
      {
        name: 'transits',
        path: OutboundPaths.transits,
        element: CallOffPage
      },
      {
        name: 'deliveryTime',
        path: OutboundPaths.deliveryTime,
        element: DeliveryTimePage
      }
    ]
  }
];
