import IApi from 'modules/shared/domain/IApi';
import BaseRepository from 'modules/shared/infrastructure/repositories/BaseRepository';
import api from 'modules/shared/infrastructure/api/Api';
import { API_DOWNLOAD_STUCKLISTE_SUPPLIERS } from './urls';
import IStucklisteSuppliersRepository from '../../domain/IStucklisteSuppliersRepository';

class StucklisteSuppliersRepository
  extends BaseRepository
  implements IStucklisteSuppliersRepository
{
  private _api: IApi;
  private _apiGetDownloadStucklisteSuppliers: string;

  constructor({ api, apiGetDownloadStucklisteSuppliers }) {
    super();
    this._api = api;
    this._apiGetDownloadStucklisteSuppliers = apiGetDownloadStucklisteSuppliers;
  }

  downloadStucklisteSuppliers({ queryParams }) {
    const url = `${
      this._apiGetDownloadStucklisteSuppliers
    }${this._createQueryParams(queryParams)}`;
    const headers = {
      Accept:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    };
    return this._api.getBlob({ url, headers });
  }
}

export default new StucklisteSuppliersRepository({
  api: api,
  apiGetDownloadStucklisteSuppliers: API_DOWNLOAD_STUCKLISTE_SUPPLIERS
});

export { StucklisteSuppliersRepository };
