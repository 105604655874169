import { createAsyncThunk } from '@reduxjs/toolkit';
import DownloadStucklisteSuppliersUseCase from 'modules/inbound/stucklisteSuppliers/application/DownloadStucklisteSuppliersUseCase';

const downloadStucklisteSuppliers = createAsyncThunk(
  'stucklisteSuppliers/downloadStucklisteSuppliers',
  async () => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const queryParams = {
      tz: timeZone
    };
    const response = await DownloadStucklisteSuppliersUseCase.execute({
      queryParams
    });
    return response;
  }
);

export { downloadStucklisteSuppliers };
