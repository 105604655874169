import { createSlice } from '@reduxjs/toolkit';
import GetDateFormatedService from 'modules/inbound/shared/domain/GetDateFormatedService';
import {
  ROLES_SELECT_LIST,
  USER_VISIBILITY_LIST,
  UserDashboards,
  UserRole,
  UserRoleText
} from 'modules/shared/domain/UserRole';
import { downloadXlsxFile } from 'modules/shared/services/downloadXlsxFile';
import {
  getUsersData,
  createUserData,
  resetUserPassword,
  deleteUser,
  downloadUsers,
  updateUserData,
  getUserProvidersData,
  getUserDashboardsData
} from './async';
import { UsersDataState } from './types';
import { getUserInboundInfo } from 'modules/inbound/shared/ui/redux/UserInboundReducer';
import { ColumnKeys } from 'modules/inbound/users/domain/UsersColumns';

export const initialState: UsersDataState = {
  data: {
    users: [],
    providers: [],
    dashboards: []
  },
  columns: [],
  pagination: {
    page: 1,
    size: 50,
    pageCount: 0,
    totalCount: 0
  },
  requestStatus: '',
  createUserStatus: '',
  response: {},
  userActionResponse: {},
  userActionRequest: '',
  usersFile: null,
  downloadRequestStatus: '',
  selectedUser: {
    email: '',
    groups: [],
    username: '',
    role: { key: '', text: '' },
    providers: [],
    dashboards: []
  },
  updateResponse: {},
  updateUserStatus: '',
  providersRequestStatus: '',
  dashboardsRequestStatus: '',
  search: '',
  selectedColumn: {
    key: '',
    isAscending: false
  },
  isOpenUserForm: false,
  isEditUserForm: false
};

const usersDataSlice = createSlice({
  name: 'usersData',
  initialState,
  reducers: {
    updateCurrentPage: (state, action) => {
      state.pagination.page = action.payload;
    },
    updateSelectedColumn: (state, action) => {
      state.selectedColumn = action.payload;
    },
    updateSelectedUser: (state, action) => {
      state.selectedUser = action.payload;
    },
    updateFilterSearch: (state, action) => {
      state.search = action.payload;
    },
    resetUserResponses: (state) => {
      state.response = initialState.response;
      state.updateResponse = initialState.updateResponse;
      state.updateUserStatus = initialState.updateUserStatus;
      state.createUserStatus = initialState.createUserStatus;
      state.userActionRequest = initialState.userActionRequest;
      state.userActionResponse = initialState.userActionResponse;
      state.selectedUser = initialState.selectedUser;
    },
    resetDownloadStatus: (state) => {
      state.downloadRequestStatus = initialState.downloadRequestStatus;
    },
    saveUserFormattedAsSelectedUser: (state, action) => {
      const role = ROLES_SELECT_LIST.find(
        (role) => role.text === action.payload.role
      ) ?? { key: UserRole.INBOUND_PROVIDER, text: UserRoleText.PROVIDER };
      const selectedVisibility = USER_VISIBILITY_LIST.filter((groups) =>
        action.payload.groups.includes(groups.text)
      ).map((item) => ({
        key: item.key,
        text: item.text
      }));
      const selectedProviders = state.data.providers
        .filter((provider) => action.payload.providers.includes(provider.id))
        .map((item) => ({
          key: item.id,
          text: `${item.id} - ${item.name}`
        }));
      const selectedDashboards = state.data.dashboards
        .filter((dashboard) => action.payload.dashboards.includes(dashboard.id))
        .map((item) => ({
          key: item.id,
          text: item.boardName
        }));
      state.selectedUser = {
        ...action.payload,
        role: role,
        groups: selectedVisibility,
        providers: selectedProviders,
        dashboards: selectedDashboards
      };
      state.isEditUserForm = true;
    },
    updateIsOpenUserForm: (state, action) => {
      state.isOpenUserForm = action.payload;
      if (!action.payload) {
        state.isEditUserForm = false;
        state.selectedUser = initialState.selectedUser;
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getUsersData.fulfilled, (state, action) => {
      state.data.users = action.payload.data;
      state.columns = action.payload.columns;
      state.pagination = action.payload.pagination;
      state.requestStatus = action.meta.requestStatus;
    });
    builder.addCase(getUsersData.pending, (state, action) => {
      state.requestStatus = action.meta.requestStatus;
    });
    builder.addCase(getUsersData.rejected, (state, action) => {
      state.requestStatus = action.meta.requestStatus;
    });
    builder.addCase(createUserData.fulfilled, (state, action) => {
      state.response = action.payload;
      state.createUserStatus = action.payload.id ? 'fulfilled' : 'rejected';
    });
    builder.addCase(createUserData.pending, (state, action) => {
      state.createUserStatus = action.meta.requestStatus;
    });
    builder.addCase(createUserData.rejected, (state, action) => {
      state.createUserStatus = action.meta.requestStatus;
    });
    builder.addCase(resetUserPassword.fulfilled, (state, action) => {
      state.userActionResponse = action.payload;
      state.userActionRequest = action.payload.id ? 'fulfilled' : 'rejected';
    });
    builder.addCase(resetUserPassword.pending, (state, action) => {
      state.userActionRequest = action.meta.requestStatus;
    });
    builder.addCase(resetUserPassword.rejected, (state, action) => {
      state.userActionRequest = action.meta.requestStatus;
    });
    builder.addCase(deleteUser.fulfilled, (state, action) => {
      state.userActionResponse = action.payload;
      state.userActionRequest = action.payload.id ? 'fulfilled' : 'rejected';
    });
    builder.addCase(deleteUser.pending, (state, action) => {
      state.userActionRequest = action.meta.requestStatus;
    });
    builder.addCase(deleteUser.rejected, (state, action) => {
      state.userActionRequest = action.meta.requestStatus;
    });
    builder.addCase(downloadUsers.fulfilled, (state, action) => {
      state.usersFile = action.payload;
      downloadXlsxFile({
        file: action.payload,
        fileName: `Users_${GetDateFormatedService.formatCompleteISODate()}`
      });
      state.downloadRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(downloadUsers.pending, (state, action) => {
      state.downloadRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(downloadUsers.rejected, (state, action) => {
      state.downloadRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(updateUserData.fulfilled, (state, action) => {
      state.updateResponse = action.payload;
      state.updateUserStatus = action.payload.id ? 'fulfilled' : 'rejected';
    });
    builder.addCase(updateUserData.pending, (state, action) => {
      state.updateUserStatus = action.meta.requestStatus;
    });
    builder.addCase(updateUserData.rejected, (state, action) => {
      state.updateUserStatus = action.meta.requestStatus;
    });
    builder.addCase(getUserProvidersData.fulfilled, (state, action) => {
      state.data.providers = action.payload.providers;
      state.providersRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(getUserProvidersData.pending, (state, action) => {
      state.providersRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(getUserProvidersData.rejected, (state, action) => {
      state.providersRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(getUserDashboardsData.fulfilled, (state, action) => {
      state.data.dashboards = action.payload.dashboards;
      state.dashboardsRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(getUserDashboardsData.pending, (state, action) => {
      state.dashboardsRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(getUserDashboardsData.rejected, (state, action) => {
      state.dashboardsRequestStatus = action.meta.requestStatus;
    });
  }
});

export const getUsersDataState = (state) => state.usersDataState.data.users;
export const getisLoadingStatus = (state) =>
  state.usersDataState.requestStatus === 'pending';
export const getHasError = (state) =>
  state.usersDataState.requestStatus === 'rejected';
export const getHasErrorCreatedUser = (state) =>
  state.usersDataState.createUserStatus === 'rejected';
export const getIsLoadingCreatedUser = (state) =>
  state.usersDataState.createUserStatus === 'pending';
export const getUserCreatedSuccessfully = (state) =>
  state.usersDataState.createUserStatus === 'fulfilled';
export const getIsUserCreatedDuplicated = (state) =>
  state.usersDataState.response.detail?.errors.includes(
    state.usersDataState.selectedUser.username
  );
export const getUserActionSuccessful = (state) =>
  state.usersDataState.userActionRequest === 'fulfilled';
export const getisLoadingActionStatus = (state) =>
  state.usersDataState.userActionRequest === 'pending';
export const getUserActionError = (state) =>
  state.usersDataState.userActionRequest === 'rejected';
export const getColumns = (state) => {
  const { role } = getUserInboundInfo(state);
  return state.usersDataState.columns.map((column) => {
    if (column.uiKey === ColumnKeys.action) {
      return {
        ...column,
        isVisibleCheck: role === UserRole.INBOUND_SUPER_ADMIN
      };
    }
    return { ...column };
  });
};
export const getSelectedColumn = (state) => state.usersDataState.selectedColumn;
export const getTotalPages = (state) =>
  state.usersDataState.pagination.pageCount;
export const getTotalCount = (state) =>
  state.usersDataState.pagination.totalCount;
export const getCurrentPage = (state) => state.usersDataState.pagination.page;
export const getResultsPerPage = (state) =>
  state.usersDataState.pagination.size;
export const getDownloadUsersSuccessful = (state) =>
  state.usersDataState.downloadRequestStatus === 'fulfilled' &&
  state.usersDataState.usersFile !== null;
export const getDownloadUsersIsPending = (state) =>
  state.usersDataState.downloadRequestStatus === 'pending';
export const getDownloadRequestError = (state) =>
  state.usersDataState.downloadRequestStatus === 'rejected';
export const getDownloadUsersFile = (state) => state.usersDataState.usersFile;
export const getSelectedUser = (state) => state.usersDataState.selectedUser;
export const getHasErrorUpdatedUser = (state) =>
  state.usersDataState.updateUserStatus === 'rejected';
export const getIsLoadingUpdatedUser = (state) =>
  state.usersDataState.updateUserStatus === 'pending';
export const getIsUserUpdatedSuccessfully = (state) =>
  state.usersDataState.updateUserStatus === 'fulfilled' &&
  !!state.usersDataState.updateResponse.id;
export const getUserProvidersDataState = (state) =>
  state.usersDataState.data.providers;
export const getisLoadingProvidersStatus = (state) =>
  state.usersDataState.providersRequestStatus === 'pending';
export const getHasErrorFetchingFormData = (state) =>
  state.usersDataState.providersRequestStatus === 'rejected' ||
  state.usersDataState.dashboardsRequestStatus === 'rejected';
export const getDashboards = (state) => {
  if (state.usersDataState.selectedUser) {
    if (
      state.usersDataState.selectedUser.role.key === UserRole.INBOUND_SEAT ||
      state.usersDataState.selectedUser.role.key ===
        UserRole.INBOUND_SUPER_ADMIN
    ) {
      return state.usersDataState.data.dashboards;
    }
    return state.usersDataState.data.dashboards.filter(
      (item) =>
        item.id === UserDashboards.NOJIT ||
        item.id === UserDashboards.JIT ||
        item.id === UserDashboards.DD ||
        item.id === UserDashboards.STUCKLISTE_SUPPLIERS
    );
  }
};
export const getFilterSearch = (state) => state.usersDataState.search;
export const getIsOpenUserForm = (state) => state.usersDataState.isOpenUserForm;
export const getIsEditUserForm = (state) => state.usersDataState.isEditUserForm;

export const {
  updateCurrentPage,
  updateSelectedColumn,
  updateSelectedUser,
  updateFilterSearch,
  updateIsOpenUserForm,
  saveUserFormattedAsSelectedUser,
  resetUserResponses,
  resetDownloadStatus
} = usersDataSlice.actions;

export default usersDataSlice.reducer;
