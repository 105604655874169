import { configureStore, PreloadedState } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import achievementSlice from 'modules/outbound/fulfillment/redux/ListAchievementsReducer/index';
import filtersSlice from 'modules/outbound/fulfillment/redux/FiltersStateReducer/index';
import deliveriesGeolocationSlice from 'modules/outbound/fulfillment/redux/DeliveriesGeolocationReducer';
import deliveriesGeolocationCheckpointsSlice from 'modules/outbound/fulfillment/redux/DeliveriesGeolocationCheckpointsReducer';
import finishedGoodsByCheckpointReducer from 'modules/outbound/fulfillment/redux/FinishedGoodsByCheckpointReducer';
import geolocatedTransportsReducer from 'modules/outbound/fulfillment/redux/GeolocatedTransportsReducer';
import warehousesOverviewReducer from 'modules/outbound/stock/redux/WarehouseStockReducer';
import pipelineStock from 'modules/outbound/stock/redux/PipelineStockReducer';
import pipelineDetailedCarInfo from 'modules/outbound/stock/redux/PipelineDetailedCarInfoReducer';
import warehouseStockByModel from 'modules/outbound/stock/redux/WarehouseStockByModelReducer';
import warehouseForecastByModel from 'modules/outbound/stock/redux/WarehouseForecastByModelReducer';
import noJitDataReducer from 'modules/inbound/nojit/ui/redux/NoJitDataReducer';
import deliveryStatusesReducer from 'modules/inbound/nojit/ui/redux/DeliveryStatusesReducer';
import suppliesDataReducer from 'modules/inbound/supplies/ui/redux/SuppliesDataReducer';
import partCoverageState from 'modules/inbound/jit/ui/redux/PartCoverageReducer';
import partCoverageFiltersState from 'modules/inbound/jit/ui/redux/PartCoverageFiltersReducer';
import callOffTrackingReducer from 'modules/outbound/callOff/infrastructure/redux/CallOffTrackingReducer';
import sharedDataReducer from 'modules/outbound/shared/ui/redux/SharedDataReducer';
import { transportCapacitiesReducer } from 'modules/outbound/capacities/ui/redux/TransportCapacitiesReducer';
import deliveryTimeReducer from 'modules/outbound/deliveryTime/ui/redux/DeliveryTimeReducer';
import suppliesFiltersReducer from 'modules/inbound/supplies/ui/redux/SuppliesFiltersStateReducer';
import noJitFiltersReducer from 'modules/inbound/nojit/ui/redux/NoJitFiltersStateReducer';
import refillsDataReducer from 'modules/inbound/refills/ui/redux/RefillsDataReducer';
import warehouseCapacityReducer from 'modules/inbound/maestros/masters/ui/redux/WarehouseCapacityReducer';
import endOfProductionDateDataState from 'modules/inbound/endOfProductionDate/ui/redux/EndOfProductionDateDataReducer';
import endOfProductionFiltersData from 'modules/inbound/endOfProductionDate/ui/redux/EndOfProductionFiltersReducer';
import usersDataState from 'modules/inbound/users/ui/redux/UsersDataReducer';
import jitFamilyCoverageState from 'modules/inbound/jit/ui/redux/JitFamilyCoverageDataReducer';
import familiesCoverageState from 'modules/inbound/jit/ui/redux/FamiliesCoverageReducer';
import jitFamiliesFiltersState from 'modules/inbound/jit/ui/redux/FamiliesCoverageFiltersReducer';
import directDeliveryState from 'modules/inbound/directDelivery/ui/redux/DirectDeliveryReducer';
import directDeliveryFiltersState from 'modules/inbound/directDelivery/ui/redux/DirectDeliveryFiltersReducer';
import upcomingTransitsState from 'modules/inbound/directDelivery/ui/redux/UpcomingTransitsReducer';
import transitsHistoryState from 'modules/inbound/directDelivery/ui/redux/TransitsHistoryReducer';
import transferReducer from 'modules/inbound/transfer/ui/redux/TransferReducer';
import transfersFiltersState from 'modules/inbound/transfer/ui/redux/TransfersFiltersReducer';
import containerNeedsState from 'modules/inbound/transfer/ui/redux/ContainerNeedsReducer';
import dischargeDocksReducer from 'modules/inbound/entries/ui/redux/DischargeDocksReducer';
import lineGroupsReducer from 'modules/inbound/maestros/masters/ui/redux/LineGroupsReducer';
import factoryBcnReducer from 'modules/inbound/factoryBcn/ui/redux/FactoryBcnReducer';
import factoryBcnFilters from 'modules/inbound/factoryBcn/ui/redux/FactoryBcnFiltersReducer';
import userInboundState from 'modules/inbound/shared/ui/redux/UserInboundReducer';
import entriesDataState from 'modules/inbound/entries/ui/redux/EntriesDataReducer';
import entriesInPlantState from 'modules/inbound/entries/ui/redux/EntriesInPlantReducer';
import entriesFiltersState from 'modules/inbound/entries/ui/redux/EntriesFiltersReducer';
import entriesInPlantFiltersState from 'modules/inbound/entries/ui/redux/EntriesInPlantFiltersReducer';
import sharedReducer from 'modules/shared/infrastructure/redux/SharedReducer';
import inboundMessagesState from 'modules/inbound/shared/ui/redux/InboundMessagesReducer';
import { userReducer } from 'modules/shared/infrastructure/redux/UserReducer';
import expirationsDataState from 'modules/inbound/expiration/ui/redux/ExpirationDataReducer';
import expirationFiltersData from 'modules/inbound/expiration/ui/redux/ExpirationFiltersReducer';
import { serviceLevelReducer } from 'modules/outbound/serviceLevel/ui/redux/ServiceLevelReducer';
import providerCodesReducer from 'modules/inbound/shared/ui/redux/ProviderCodesReducer';
import contactListState from 'modules/inbound/shared/ui/redux/ContactListReducer';
import saturationUnloadingPointsReducer from 'modules/inbound/entries/ui/redux/SaturationUnloadingPointsReducer';
import saturationUnloadingPointsFiltersReducer from 'modules/inbound/entries/ui/redux/SaturationUnloadingPointsFilters';
import contactFormState from 'modules/inbound/shared/ui/redux/ContactFormReducer';
import mastersState from 'modules/inbound/maestros/masters/ui/redux/MastersReducer';
import stucklisteSuppliersState from 'modules/inbound/stucklisteSuppliers/ui/redux/StucklisteSuppliersReducer';

export const reducer = combineReducers({
  achievementState: achievementSlice.reducer,
  filtersState: filtersSlice.reducer,
  suppliesFiltersState: suppliesFiltersReducer.reducer,
  noJitFiltersState: noJitFiltersReducer.reducer,
  deliveriesGeolocationState: deliveriesGeolocationSlice.reducer,
  deliveriesGeolocationCheckpointsState:
    deliveriesGeolocationCheckpointsSlice.reducer,
  finishedGoodsByCheckpointState: finishedGoodsByCheckpointReducer,
  geolocatedTransportsState: geolocatedTransportsReducer,
  warehousesOverviewState: warehousesOverviewReducer,
  warehouseStockByModelState: warehouseStockByModel.reducer,
  warehouseForecastByModelState: warehouseForecastByModel.reducer,
  pipelineStockState: pipelineStock.reducer,
  pipelineDetailedCarInfoState: pipelineDetailedCarInfo.reducer,
  noJitDataState: noJitDataReducer,
  deliveryStatusesState: deliveryStatusesReducer,
  suppliesDataState: suppliesDataReducer,
  partCoverageState: partCoverageState,
  partCoverageFiltersState: partCoverageFiltersState,
  jitFamilyCoverageState: jitFamilyCoverageState,
  familiesCoverageState: familiesCoverageState,
  jitFamiliesFiltersState: jitFamiliesFiltersState,
  callOffTrackingState: callOffTrackingReducer,
  sharedDataState: sharedDataReducer,
  deliveryTimeState: deliveryTimeReducer,
  refillsDataState: refillsDataReducer,
  warehouseCapacityState: warehouseCapacityReducer,
  lineGroupsState: lineGroupsReducer,
  endOfProductionDateDataState: endOfProductionDateDataState,
  endOfProductionFiltersData: endOfProductionFiltersData,
  usersDataState: usersDataState,
  directDeliveryState: directDeliveryState,
  directDeliveryFiltersState: directDeliveryFiltersState,
  upcomingTransitsState: upcomingTransitsState,
  transitsHistoryState: transitsHistoryState,
  transferState: transferReducer,
  transfersFiltersState: transfersFiltersState,
  containerNeedsState: containerNeedsState,
  dischargeDocksState: dischargeDocksReducer,
  entriesDataState: entriesDataState,
  entriesInPlantState: entriesInPlantState,
  entriesFiltersState: entriesFiltersState,
  entriesInPlantFiltersState: entriesInPlantFiltersState,
  factoryBcnState: factoryBcnReducer,
  factoryBcnFilters: factoryBcnFilters,
  userInboundState: userInboundState,
  sharedState: sharedReducer,
  inboundMessagesState: inboundMessagesState,
  expirationFiltersData: expirationFiltersData,
  userState: userReducer,
  expirationsDataState: expirationsDataState,
  transportCapacitiesState: transportCapacitiesReducer,
  serviceLevelState: serviceLevelReducer,
  providerCodesState: providerCodesReducer,
  contactListState: contactListState,
  saturationUnloadingPointsState: saturationUnloadingPointsReducer,
  saturationUnloadingPointsFiltersState:
    saturationUnloadingPointsFiltersReducer,
  contactFormState: contactFormState,
  mastersState: mastersState,
  stucklisteSuppliersState: stucklisteSuppliersState
});

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    }),
  devTools: process.env.NODE_ENV !== 'production'
});

export const getNewStore = (preloadedState?: PreloadedState<AppState>) =>
  configureStore({
    reducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false
      }),
    devTools: process.env.NODE_ENV !== 'production'
  });

export type AppState = ReturnType<typeof reducer>;
export type AppDispatch = typeof store.dispatch;
export default store;
